import {
  Box,
  Grid,
  CircularProgress,
  CreditCardIcon,
} from "@enerbit/base";
import CustomCard from "@enerbit/base/common/components/CustomCard";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { enumsStatePayments } from "../../enums/payment";
import {
  amounts, 
  isLoadingStatistics,
  selectedFilterPayment,
  setPayments, 
  setResetPage
} from "../../features/payments/payment";
import { AppDispatch } from "../../store/store"; 

const CardOverview = () => {
  const selectedCard = useSelector(selectedFilterPayment);
  const totalAmounts = useSelector(amounts);
  const dispatch = useDispatch<AppDispatch>();
  const loading = useSelector(isLoadingStatistics);

  const PaymentsCard = (value: string, amount: number) => {
    return <CustomCard
      onClick={() => {
        dispatch(setPayments(value)) 
      }} 
      icon={<CreditCardIcon color="primary"/> }
      text={enumsStatePayments[value as keyof typeof enumsStatePayments]}
      value={value}
      totalAmounts={amount}
      selectedCard={selectedCard}
    /> 
  }

  useEffect(() => {
    dispatch(setResetPage())
  }, [selectedCard])

  return (
    <>
      {loading ? (
        <Box className="Loading-flex">
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          <Grid sx={{ flexGrow: 1, mt: "12px" }} container>
            <Grid item xs={12} md={3} lg={3} sx={{ pl: "10px" }}>
              { PaymentsCard("Pending", totalAmounts.pending?.pendingPaymentsAmount || 0) } 
            </Grid>
            <Grid item xs={12} md={3} lg={3} sx={{ pl: "10px" }}>
              { PaymentsCard("Succeeded", totalAmounts.success?.successPaymentsAmount || 0) }  
            </Grid>
            <Grid item xs={12} md={3} lg={3} sx={{ pl: "10px" }}>
              { PaymentsCard("Failed", totalAmounts.failed?.failedPaymentsAmount || 0) }   
            </Grid>
            <Grid item xs={12} md={3} lg={3} sx={{ pl: "10px" }}>
              { PaymentsCard("Created", totalAmounts.created?.createdInvoiceAmount || 0) }    
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default CardOverview;
