import { 
  initEnviroment,  
} from "@enerbit/base";   
import "./assets/css/payment.scss";  
import Routers from "./router/Routers";   

initEnviroment({
  baseUrl: process.env.REACT_APP_BASE_URL
});

export default function Root() {    
  return  <Routers />   
}
