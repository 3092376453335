import {
  Box,
  Button,
  DownloadOutlinedIcon, 
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Pagination, 
  moment,
  CSVLink,
  getPaymentsMethod,
  formatterPeso,
  ModalLimitDownload, 
} from "@enerbit/base"; 
import { ChangeEvent, createRef, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  count, 
  handlePageChange,
  infoPaymentsCreated,
  infoPaymentsPending,
  infoPaymentsSucceeded,
  isLoadingDownload,
  paymentsDownload,
  page,
  paymentsShow,
  selectedFilterPayment,
  setDetail,
  setPayments,
  setResetDownloadPayments,
  getDownloadCsv,
  getDetailPaymentInvoices,
  amounts
} from "../../features/payments/payment";  
import { CustomModalTypes, Payment } from "../../models/paymentState";
import { AppDispatch } from "../../store/store";
import DetailForm from "./DetailForm";
import { LoadingButton } from "@enerbit/base"; 
import { headersPayments } from "../../helpers/helpersPayment";

const TablePayments = () => {
  const countInvoices = useSelector(count);
  const pageInvoices = useSelector(page);
  const items = useSelector(paymentsShow);
  const loadingDownload = useSelector(isLoadingDownload);
  const paymentDownload = useSelector(paymentsDownload);
  const dispatch = useDispatch<AppDispatch>();
  const selectedCard = useSelector(selectedFilterPayment);
  const detailPayment = useRef<CustomModalTypes>(null); 
  const csvLinkEl = createRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>();
  const totalAmounts = useSelector(amounts);
  const limitDownload = useRef<CustomModalTypes>(null);

  const callPagination = async (event: ChangeEvent<unknown>, value: number) => 
  {
    dispatch(handlePageChange(value - 1));
    if(selectedCard == "Pending")
    {
      await dispatch(infoPaymentsPending());
      dispatch(setPayments("Pending"))
    }
    else if(selectedCard == "Succeeded")
    {
      await dispatch(infoPaymentsSucceeded());
      dispatch(setPayments("Succeeded"))
    }
    else if(selectedCard == "Failed")
    {
      await dispatch(infoPaymentsSucceeded());
      dispatch(setPayments("Failed"))
    }
    else
    {
      await dispatch(infoPaymentsCreated()); 
      dispatch(setPayments("Created")) 
    }
  } 

  const callDetailPayment = (row: Payment) => 
  { 
    dispatch(setDetail(row.id));
    dispatch(getDetailPaymentInvoices(row.id));

    if(detailPayment.current)
    {
      detailPayment.current.changeModal();  
    }
  }; 

  const csvReport = {
    data: paymentDownload,
    headers: headersPayments,
    filename: moment().format("YMMDD")+"_pagos.csv"
  };
  
  const callLimitDownload = () => {
    if (limitDownload.current) {
      limitDownload.current.changeModal();
    }
  };

  const getDownload = () => 
  { 
    let totalValue = 0;

    if( selectedCard == "Pending"){
      totalValue = totalAmounts.pending?.pendingPaymentsAmount || 0
    }   
    if( selectedCard == "Succeeded"){
      totalValue = totalAmounts.success?.successPaymentsAmount || 0
    }  
    if( selectedCard == "Failed"){
      totalValue = totalAmounts.failed?.failedPaymentsAmount || 0
    }   
    if( selectedCard == "Created"){
      totalValue = totalAmounts.created?.createdInvoiceAmount || 0
    }      

    if (totalValue > 500) {
      callLimitDownload();
    } else {
      dispatch(getDownloadCsv(selectedCard));
    }  

  }

  useEffect(() => {
    if(paymentDownload.length > 0)
    {
      if(csvLinkEl.current)
      {
        csvLinkEl.current.link.click();
        dispatch(setResetDownloadPayments());
      } 
    }
  }, [paymentDownload])

  return (
    <>
      <DetailForm ref={detailPayment} /> 
      <Box sx={{ display: "flex", justifyContent: "end", mt: "10px" }}>
        <LoadingButton 
            loading={loadingDownload}
            variant="outlined"
            onClick={getDownload}
            color="primary"
            size="small"
            sx={{ margin: "1.8rem 0 0.5rem 0" }}
            startIcon={<DownloadOutlinedIcon />}
          >
            <Box style={{ fontWeight: "bold", margin: 0, textDecoration: "none" }}>Descargar</Box>
          </LoadingButton> 
          <CSVLink ref={csvLinkEl} className="Csv-download" {...csvReport}> 
        </CSVLink> 
      </Box> 
      <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
        <Table className="Table-billing" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">ID Transacción</TableCell>
              <TableCell align="left">ID Integrador</TableCell>
              <TableCell align="left">Correo del pagador</TableCell>
              <TableCell align="left">Fecha de pago</TableCell>
              <TableCell align="left">Medio de pago</TableCell>
              <TableCell align="left">Total pagado</TableCell>  
              <TableCell></TableCell> 
            </TableRow>
          </TableHead>
          <TableBody>
            {items &&
              items.map((row, index) => {  
                return (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="left"> { row.integration_transaction_id } </TableCell>
                    <TableCell align="left"> { row.integration_id } </TableCell>
                    <TableCell align="left"> { row.payer_email } </TableCell>
                    <TableCell align="left"> { moment.parseZone(row.created_at).local().format("YYYY-MM-DD HH:mm:ss") } </TableCell>
                    <TableCell align="left"> { getPaymentsMethod(row.payment_method) } </TableCell>
                    <TableCell align="left" sx={{fontWeight: "bold"}}> { formatterPeso.format(row.value) } </TableCell>  
                    <TableCell align="left">
                      <Button
                        variant="contained"
                        color="secondary"
                        size="small" 
                        className="Loading-button Button-xs"
                        onClick={() => callDetailPayment(row)}
                      >
                        <Box style={{ fontWeight: "bold", margin: 0 }}>
                          Ver detalle
                        </Box>
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box className="Pagination-invoices">
        <Pagination
          count={countInvoices}
          shape="rounded"
          page={pageInvoices + 1}
          sx={{ margin: "0.3rem 0 1rem 0" }}
          onChange={callPagination}
        />
      </Box>
      <ModalLimitDownload ref={limitDownload} />
    </>
  );
};

export default TablePayments;
