import { api, initDownload } from "@enerbit/base";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getParamsPayments } from "../../helpers/helpersPayment";
import {
  PaymentState,  
  ParamFilters,
  RootObject,
  Payment,
  DetailPaymentInvoices,
  PaymentMethods,
} from "../../models/paymentState";
import { StateStorage } from "../../models/stateStorage";

const paymentState = {
  items: [],
  page: 0,
  size: 0,
  total: 0,
  pages: 0,
  next_page: 0,
  previous_page: 0,
  amount: 0,
}

const initialState: PaymentState = {
  isLoading: false, 
  paymentSelect: "",
  params: {},
  page: 0,
  size: 20,
  count: 0,
  city: "", 
  isLoadingDetail: false,
  isLoadingDownload: false,
  paymentsPending: paymentState,
  paymentsSucceeded: paymentState,
  paymentsFailed: paymentState,
  paymentsCreated: paymentState,
  paymentsDownload: [],
  paymentMethods: [],
  paymentsShow: [],
  selectedFilterPayment: "Pending",  
  isLoadingStatistics: false, 
  detailInvoice: null,
  totalPayment: 0,
  countPaymentsPending: 0,
  countPaymentsSucceeded: 0,
  countPaymentsFailed: 0,
  countPaymentsCreated: 0,
  detailPayment: {},
  amounts: {},
};

export const getDownloadCsv = createAsyncThunk(
  "payments/getDownloadCsv",
  async (typeDownload: string, thunkAPI) => {  

    let payments: Payment[] = [];
    const state = thunkAPI.getState() as StateStorage; 
    let varSelected =  "payments"+typeDownload;
    let query = state.infoBillingState[varSelected as keyof typeof state.infoBillingState] as RootObject 
 
    if(query.total <= 100) 
      {
        const params = {
          page: 0,
          size: 100,
          with_state_in: typeDownload,
          ...state.infoBillingState.params
        } 
        const res = await api.get("/payments/transactions/", { params : params }); 
        payments = res.data.items;
      }
      else
      { 
        for (let index = 0; index < query?.pages; index++) { 
          const params = {
            page: index,
            size: query.size,
            with_state_in: typeDownload,
            ...state.infoBillingState.params
          } 
          const res = await api.get("/payments/transactions/", { params : params }); 
          payments = [...payments,...res.data.items]
        } 
      }   
      return payments;  
  }
);   

export const getPaymentMethods = createAsyncThunk(
  "payments/getPaymentMethods",
  async () => {  
    const paymentMethods = await api.get(`/payments/payment-methods/`); 
    return paymentMethods.data.items;
  }  
);  

export const getDetailPaymentInvoices = createAsyncThunk(
  "payments/transactions",
  async (id: string, thunkAPI:any ) => {  
    const detailPayment = await api.get(`/payments/transactions/${id}/explain`); 
    return detailPayment.data;
  }  
);  

export const getPayments = createAsyncThunk(
  "payments/getPayments",
  async (_, thunkAPI) => {
    // Promise.all([
    //   thunkAPI.dispatch(infoPaymentsPending()),
    //   thunkAPI.dispatch(infoPaymentsSucceeded()),
    //   thunkAPI.dispatch(infoPaymentsFailed()),
    //   thunkAPI.dispatch(infoPaymentsCreated()), 
    // ]); 

    await thunkAPI.dispatch(infoPaymentsPending());
    await thunkAPI.dispatch(infoPaymentsSucceeded());
    await thunkAPI.dispatch(infoPaymentsFailed());
    await thunkAPI.dispatch(infoPaymentsCreated());  
  }
);

export const infoPaymentsPending = createAsyncThunk(
  "payments/infoPaymentsPending",
  async (_, thunkAPI) => {
    const state = thunkAPI.getState() as StateStorage;
    const params = getParamsPayments(state, "Pending");
    const res = await api.get("/payments/transactions/", params);  
    return res.data;
  }
);

export const infoPaymentsSucceeded = createAsyncThunk(
  "payments/infoPaymentsSucceeded",
  async (_, thunkAPI) => {
    const state = thunkAPI.getState() as StateStorage;
    const params = getParamsPayments(state, "Succeeded");
    const res = await api.get("/payments/transactions/", params);  
    return res.data;
  }
);

export const infoPaymentsFailed = createAsyncThunk(
  "payments/infoPaymentsFailed",
  async (_, thunkAPI) => {
    const state = thunkAPI.getState() as StateStorage;
    const params = getParamsPayments(state, "Failed");
    const res = await api.get("/payments/transactions/", params);  
    return res.data;
  }
);

export const infoPaymentsCreated = createAsyncThunk(
  "payments/infoPaymentsCreated",
  async (_, thunkAPI) => {
    const state = thunkAPI.getState() as StateStorage;
    const params = getParamsPayments(state, "Created");
    const res = await api.get("/payments/transactions/", params);  
    return res.data;
  }
);

export const infoBillingSlice = createSlice({
  name: "infoBillingState",
  initialState,
  reducers: {
    setPayments: (state, action: PayloadAction<string>) => {
      state.selectedFilterPayment = action.payload;
      if (action.payload === "Pending") {
        state.paymentsShow = state.paymentsPending.items || [];
        state.count = state.countPaymentsPending;
      } else if (action.payload === "Succeeded") {
        state.paymentsShow = state.paymentsSucceeded.items || [];
        state.count = state.countPaymentsSucceeded;
      } else if (action.payload === "Failed") {
        state.paymentsShow = state.paymentsFailed.items || [];
        state.count = state.countPaymentsFailed;
      } else if (action.payload === "Created") {
        state.paymentsShow = state.paymentsCreated.items || [];
        state.count = state.countPaymentsCreated;
      }
    },
    setResetPage: (state) => {
      state.page = 0;
    }, 
    setResetDownloadPayments: (state) => {
      state.paymentsDownload = []; 
      //localStorage.setItem('isDownload', "false"); 
      initDownload(false);
    }, 
    setParams: (state, action: PayloadAction<ParamFilters>) => {
      state.params = action.payload;
    },   
    handlePageChange: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setDetail: (state, action: PayloadAction<string>) => {   
      state.paymentSelect = action.payload;  
    },
  },
  extraReducers: (builder) => {
    builder 
    .addCase(getPaymentMethods.fulfilled.type, (state, action: PayloadAction<PaymentMethods[]>) => {
      state.paymentMethods = action.payload;
    }) 
    .addCase(getDetailPaymentInvoices.pending.type, (state) => {
      state.isLoadingDetail = true;
    }) 
    .addCase(getDetailPaymentInvoices.rejected.type, (state) => {
      state.isLoadingDetail = false; 
      state.detailPayment = {};
    }) 
    .addCase(getDetailPaymentInvoices.fulfilled.type, (state, action: PayloadAction<DetailPaymentInvoices>) => {  
      state.detailPayment = action.payload;
      state.isLoadingDetail = false; 
    })
    .addCase(getDownloadCsv.pending.type, (state) => {
      state.isLoadingDownload = true; 
      //localStorage.setItem('isDownload', "true"); 
      initDownload(true);   
    }) 
    .addCase(getDownloadCsv.rejected.type, (state) => {
      state.isLoadingDownload = false;  
    }) 
    .addCase(getDownloadCsv.fulfilled.type, (state, action: PayloadAction<Payment[]>) => {   
      state.isLoadingDownload = false; 
      state.paymentsDownload = action.payload;
    }) 
    .addCase(getPayments.pending.type, (state) => {
      state.isLoadingStatistics = true;
    })
    .addCase(getPayments.rejected.type, (state) => {
      state.isLoadingStatistics = false;
    })
    .addCase(getPayments.fulfilled.type, (state) => {
      state.isLoadingStatistics = false;
    })
    .addCase(infoPaymentsPending.pending.type, ( state ) => {
      state.isLoading = true;
    })
    .addCase(infoPaymentsPending.rejected.type, ( state ) => {
      state.isLoading = false;
      state.paymentsPending = paymentState;
      state.amounts.pending = {
        pendingPaymentsAmount: 0
      }
      state.countPaymentsPending = 0;
    })
    .addCase(infoPaymentsPending.fulfilled.type, ( state, action: PayloadAction<RootObject>) => {
      state.isLoading = false;
      state.paymentsPending = action.payload;
      state.amounts.pending = {
        pendingPaymentsAmount: action.payload.total,
      };
      state.countPaymentsPending = action.payload.pages;
    })
    .addCase(infoPaymentsSucceeded.pending.type, ( state ) => {
      state.isLoading = true;
    })
    .addCase(infoPaymentsSucceeded.rejected.type, ( state ) => { 
      state.isLoading = false;  
      state.paymentsSucceeded = paymentState;
      state.amounts.success = {
        successPaymentsAmount: 0
      }
      state.countPaymentsSucceeded = 0;
    })
    .addCase(infoPaymentsSucceeded.fulfilled.type, ( state, action: PayloadAction<RootObject>) => {
      state.isLoading = false;
      state.paymentsSucceeded = action.payload;
      state.amounts.success = {
        successPaymentsAmount: action.payload.total,
      };
      state.countPaymentsSucceeded = action.payload.pages;
    })
    .addCase(infoPaymentsFailed.pending.type, ( state ) => {
      state.isLoading = true;
    })
    .addCase(infoPaymentsFailed.rejected.type, ( state ) => {  
      state.isLoading = false;  
      state.paymentsFailed = paymentState;
      state.amounts.failed = {
        failedPaymentsAmount: 0
      }
      state.countPaymentsFailed = 0; 
    })
    .addCase(infoPaymentsFailed.fulfilled.type, ( state, action: PayloadAction<RootObject>) => {
      state.isLoading = false;
      state.paymentsFailed = action.payload;
      state.amounts.failed = {
        failedPaymentsAmount: action.payload.total,
      };
      state.countPaymentsFailed = action.payload.pages;
    })
    .addCase(infoPaymentsCreated.pending.type, ( state ) => {
      state.isLoading = true;
    })
    .addCase(infoPaymentsCreated.rejected.type, ( state ) => {  
      state.isLoading = false;  
      state.paymentsCreated = paymentState;
      state.amounts.created = {
        createdInvoiceAmount: 0
      }
      state.countPaymentsCreated = 0; 
    })
    .addCase(infoPaymentsCreated.fulfilled.type, ( state, action: PayloadAction<RootObject>) => {
      state.isLoading = false;
      state.paymentsCreated = action.payload;
      state.amounts.created = {
        createdInvoiceAmount: action.payload.total,
      };
      state.countPaymentsCreated = action.payload.pages;
    })
  },
});

export const {
  setParams, 
  handlePageChange,   
  setPayments,
  setResetPage, 
  setResetDownloadPayments,
  setDetail
} = infoBillingSlice.actions;

export const isLoading = (state: StateStorage) => state.infoBillingState.isLoading;
export const params = (state: StateStorage) => state.infoBillingState.params;  
export const detailInvoice = (state: StateStorage) => state.infoBillingState.detailInvoice;
export const totalPayment = (state: StateStorage) => state.infoBillingState.totalPayment;
export const selectedFilterPayment = (state: StateStorage) => state.infoBillingState.selectedFilterPayment;
export const page = (state: StateStorage) => state.infoBillingState.page;
export const size = (state: StateStorage) => state.infoBillingState.size;
export const count = (state: StateStorage) => state.infoBillingState.count;
export const amounts = (state: StateStorage) => state.infoBillingState.amounts;
export const isLoadingStatistics = (state: StateStorage) => state.infoBillingState.isLoadingStatistics;
export const paymentsShow = (state: StateStorage) => state.infoBillingState.paymentsShow; 
export const countPaymentsCreated = (state: StateStorage) => state.infoBillingState.countPaymentsCreated;
export const countPaymentsFailed = (state: StateStorage) => state.infoBillingState.countPaymentsFailed;
export const countPaymentsPending = (state: StateStorage) => state.infoBillingState.countPaymentsPending;
export const countPaymentsSucceeded = (state: StateStorage) => state.infoBillingState.countPaymentsSucceeded;
export const paymentsCreated = (state: StateStorage) => state.infoBillingState.paymentsCreated;
export const paymentsFailed = (state: StateStorage) => state.infoBillingState.paymentsFailed;
export const paymentsPending = (state: StateStorage) => state.infoBillingState.paymentsPending;
export const paymentsSucceeded = (state: StateStorage) => state.infoBillingState.paymentsSucceeded;  
export const isLoadingDetail = (state: StateStorage) => state.infoBillingState.isLoadingDetail; 
export const paymentSelect = (state: StateStorage) => state.infoBillingState.paymentSelect; 
export const isLoadingDownload = (state: StateStorage) => state.infoBillingState.isLoadingDownload; 
export const paymentsDownload = (state: StateStorage) => state.infoBillingState.paymentsDownload;  
export const detailPayment = (state: StateStorage) => state.infoBillingState.detailPayment;    
export const paymentMethods = (state: StateStorage) => state.infoBillingState.paymentMethods;    

export default infoBillingSlice.reducer;
