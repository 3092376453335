import FiltersOverview from "./reusable/FiltersOverview";
import CardOverview from "./reusable/CardOverview";
import TableBilling from "./reusable/TablePayments";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../store/store";
import { useEffect } from "react";
import { getPaymentMethods, getPayments, isLoading, setPayments } from "../features/payments/payment";
import { CircularProgress, Box } from "@enerbit/base";

const SectionInformation = () => {
  const dispatch = useDispatch<AppDispatch>();
  const loading  = useSelector(isLoading);

  useEffect(() => {  
    callInitialStatement()
  }, [])

  const callInitialStatement = async () => {
    await dispatch(getPaymentMethods());  
    await dispatch(getPayments()) 
    dispatch(setPayments("Pending")); 
  }

  return (
    <>
      <FiltersOverview />
      <CardOverview />
      {
          loading ?
            <Box className="Loading-flex">
              <CircularProgress />
            </Box>
          : ( 
            <TableBilling /> 
          )
        }
    </>
  );
};

export default SectionInformation;
