import { moment } from "@enerbit/base";
import { ObjectKeyValue, ParamsAsync } from "../models/paymentState";
import { StateStorage } from "../models/stateStorage";

export const getStatusDate = (payment: Date | null, expiration_date: Date) => {
  let status = "No pagada";
  if (payment != null) {
    status = "Pagada";
  } else {
    if (new Date(expiration_date) < new Date()) status = "Vencida";
    else status = "No pagada";
  }
  return status;
};

export const getTypeStatusInvoices = (value: string) => {
  let status: ObjectKeyValue = {
    "No pagada": "warning",
    Pagada: "success",
    Vencida: "error",
  };

  return status[value];
};

export const getParamsPayments = (state: StateStorage, paymentType: string) => {
  let params: ParamsAsync = {
    params: {
      page: state.infoBillingState.page,
      size: state.infoBillingState.size,
      /* sizeTableDefaultDownload: state.infoBillingState.sizeTableDefaultDownload */
      ...state.infoBillingState.params,
      with_state_in: paymentType,
    },
  };
  return params;
};

export const getDatePayExpired = (expirationDate: Date) => {
  var dateExpires = moment(expirationDate);
  var dateSubstract = moment();

  let days = dateSubstract.diff(dateExpires, "days");

  if (days < 0) {
    return "0";
  } else {
    return days;
  }
};

export const headersPayments = [
  { label: "ID Transacción", key: "integration_transaction_id" },
  { label: "ID Integrador", key: "integration_id" },
  { label: "Fecha de pago", key: "created_at" },
  { label: "Medio de pago", key: "payment_method" },
  { label: "Total pagado", key: "value" },
];
