import {
  AdapterDayjs,
  Box,
  Button,
  DatePicker,
  Dayjs,
  dayjs,
  Grid,
  LocalizationProvider,
  MenuItem,
  moment,
  paymentsMethods,
  Select,
  SelectChangeEvent,
} from "@enerbit/base";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getPayments,
  params,
  paymentMethods,
  selectedFilterPayment,
  setParams,
  setPayments,
  setResetPage,
} from "../../features/payments/payment";
import { ParamFilters } from "../../models/paymentState";
import { AppDispatch } from "../../store/store";

const FiltersOverview = () => {
  const filters = useSelector(params);
  const dispatch = useDispatch<AppDispatch>();
  const selectedCard = useSelector(selectedFilterPayment);
  const paymentsMethod = useSelector(paymentMethods);

  const changeParams = (value: ParamFilters) => {
    dispatch(setParams({ ...filters, ...value }));
    dispatch(setResetPage());
  };

  const cleanFilters = async () => {
    dispatch(setResetPage());
    dispatch(setParams({}));
    await dispatch(getPayments());
    dispatch(setPayments(selectedCard));
  };

  useEffect(() => {
    if (
      filters?.with_created_after &&
      filters?.with_created_after != null &&
      (!filters?.with_created_before || filters?.with_created_before == null)
    ) {
      changeParams({
        with_created_before: new Date(
          moment(filters?.with_created_after).add(1, "months").toString()
        ).toISOString(),
      });
    }

    if (
      filters?.with_created_before &&
      filters?.with_created_before != null &&
      (!filters?.with_created_after || filters?.with_created_after == null)
    ) {
      changeParams({
        with_created_after: new Date(
          moment(filters?.with_created_before).subtract(1, "months").toString()
        ).toISOString(),
      });
    }
  }, [filters?.with_created_after, filters?.with_created_before]);

  const callInitialStatement = async () => {
    await dispatch(getPayments());
    dispatch(setPayments(selectedCard));
  };

  useEffect(() => {
    if (Object.keys(filters).length > 0) {
      callInitialStatement();
    }
  }, [filters]);

  return (
    <>
      <form>
        <Grid sx={{ flexGrow: 1 }} container>
          <Grid item xs={12} md={3} lg={3} sx={{ pl: "10px" }}>
            <Box sx={{ mb: "10px" }}>Fecha inicial</Box>
            <Box className="TextField-without-border-radius Text-select-field">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={
                    filters?.with_created_after == null
                      ? null
                      : dayjs(filters?.with_created_after)
                  }
                  onChange={(value: Dayjs | null) => {
                    changeParams({
                      with_created_after:
                        value != null ? value.toISOString() : null,
                    });
                  }}
                  format="yyyy-MM-dd"
                  slotProps={{
                    textField: {
                      inputProps: {
                        placeholder: "De",
                      },
                      sx: { width: "100%" },
                    },
                  }}
                />
              </LocalizationProvider>
            </Box>
          </Grid>
          <Grid item xs={12} md={3} lg={3} sx={{ pl: "10px" }}>
            <Box sx={{ mb: "10px" }}>Fecha final</Box>
            <Box className="TextField-without-border-radius Text-select-field">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={
                    filters?.with_created_before == null
                      ? null
                      : dayjs(filters?.with_created_before)
                  }
                  onChange={(value: Dayjs | null) => {
                    changeParams({
                      with_created_before:
                        value != null ? value.toISOString() : null,
                    });
                  }}
                  format="yyyy-MM-dd"
                  slotProps={{
                    textField: {
                      inputProps: {
                        placeholder: "Hasta",
                      },
                      sx: { width: "100%" },
                    },
                  }}
                />
              </LocalizationProvider>
            </Box>
          </Grid>
          <Grid item xs={12} md={4} lg={4} sx={{ pl: "10px" }}>
            <Box sx={{ mb: "10px" }}>Método de pago</Box>
            <Box className="TextField-without-border-radius Text-select-field">
              <Select
                fullWidth
                value={filters.with_payment_method_id || ""}
                onChange={(event: SelectChangeEvent<string | null>) =>
                  changeParams({ with_payment_method_id: event.target.value })
                }
              >
                <MenuItem value={""}></MenuItem>
                {paymentsMethod &&
                  paymentsMethod.map((value, index) => (
                    <MenuItem key={index} value={value.id}>
                      {paymentsMethods[value.code]}
                    </MenuItem>
                  ))}
              </Select>
            </Box>
          </Grid>
          <Grid item xs={12} md={1} lg={1}>
            <Box
              display="flex"
              alignItems="center"
              sx={{ mt: "10px", pt: "16px", pl: "10px" }}
            >
              <Button
                className="Clean-filters"
                color="primary"
                size="small"
                onClick={() => {
                  cleanFilters();
                }}
              >
                <Box className="Clean-filters-text">Limpiar todo</Box>
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default FiltersOverview;
